import React, { useEffect } from "react";
import GPAC from "../images/corner/corner-gpac.webp";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const PrivateEvents = ({ info }) => {
  useEffect(() => {
    document.title = "Auburn Road - Private Events";
    return () => {
      document.title = "Auburn Road Vineyards";
    };
  }, []);

  const renderImages = () => {
    const images = info[0].fields.images.map((img) => {
      return (
        <div className="m-b-10 m-r-10">
          <img
            className=" max-height-300"
            src={img.fields.file.url}
            alt={img.fields.title}
          />
        </div>
      );
    });
    return (
      <div className="d-flex flex-column flex-md-row justify-content-between align-content-center flex-wrap flex-md-nowrap">
        {images}
      </div>
    );
  };

  return (
    <div className="position-relative p-b-200">
      <img className="corner-gpac" src={GPAC} alt="" />
      <div className="inner-container m-auto p-t-150">
        <h2 className="huge-text-no-quote m-b-100">Private Events</h2>
        {documentToReactComponents(info[0].fields.content)}
        {renderImages()}
      </div>
    </div>
  );
};

export default PrivateEvents;
