import React, { useEffect } from "react";
import Button from "../components/Button";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { MARKS, BLOCKS } from "@contentful/rich-text-types";

const Cowtown = ({ info }) => {
  useEffect(() => {
    document.title = "Auburn Road - Cowtown Covered Wagon Tours";
    return () => {
      document.title = "Auburn Road Vineyards";
    };
  }, []);

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <span className="fw-bold">{text}</span>,
      [MARKS.ITALIC]: (text) => <span className="i">{text}</span>,
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <img
          className="w-50 m-auto"
          src={node.data?.target?.fields?.file?.url}
          alt={node.data?.target?.fields?.title}
        />
      ),
    },
  };

  const renderCards = () => {
    const cards = info.fields.cards.map((card) => {
      const [buttonText, link] = card.fields.description.split("-");
      const title = card.fields.title;
      const img = card.fields.file.url;
      return (
        <div className="col-12 col-sm-6 col-md-3 col-lg-4 mb-4 pointer-on-hover">
          <div className="card">
            <img src={img} className="card-img-top" alt={title} />
            <div className="card-body">
              <p className="card-text">{title}</p>
              <Button
                onClick={() => window.open(link)}
                className="button button__secondary"
              >
                {buttonText}
              </Button>
            </div>
          </div>
        </div>
      );
    });
    return cards;
  };

  return (
    <div className="container p-t-100 p-b-200">
      <h1 className="huge-text-no-quote mb-5">{info.fields.heading}</h1>

      <div className="row m-t-100">
        <div className="col-lg-6 col-md-12 mb-4 width-400 m-r-50">
          <iframe
            width="100%"
            height="715"
            src="https://youtube.com/embed/oOySLYVMKpw?si=iTW0JXe9hSOhGF8P"
            frameborder="0"
            allowfullscreen
            title="cowtown video"
          ></iframe>
        </div>

        <div className="col-lg-6 col-md-12">
          {documentToReactComponents(info.fields.bodyText, options)}
          <Button
            onClick={() =>
              window.open(
                "https://www.exploretock.com/auburnroadvineyards/event/501491/cowtown-auburn-road-wagon-tour"
              )
            }
            className="button__secondary"
          >
            Get Tickets
          </Button>
        </div>
      </div>
      <div className="row m-t-150">{renderCards()}</div>
      <h1 className="m-t-100 ">Don't forget...</h1>
      <p className="width-600">
        to make reservations for before or after your tour at Auburn Road!
      </p>
      <Button
        onClick={() =>
          window.open("https://www.exploretock.com/auburnroadvineyards/")
        }
        className="brush-button mt-4"
      >
        Make a Reservation
      </Button>
    </div>
  );
};
export default Cowtown;
