import React, { useEffect } from "react";
import SpiritAnimalLogo from "../images/spirit-animal-logo.svg";
import Lioness from "../images/lioness.png";
import ReservationCTA from "../components/ReservationCTA";
import Hummingbird from "../images/hummingbird.png";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { MARKS } from "@contentful/rich-text-types";

const SpiritAnimal = (props) => {
  useEffect(() => {
    document.title = "Auburn Road - Spirit Animal Brand";
    return () => {
      document.title = "Auburn Road Vineyards";
    };
  }, []);

  const { data } = props;
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <span className="fw-bold">{text}</span>,
    },
  };

  return (
    <>
      <img src={Hummingbird} className="full-hummingbird" alt="" />
      <img src={Lioness} className="corner-lioness" alt="" />
      <div className="container inner-container m-auto p-t-100 m-b-200 spirit-animal">
        <img
          className="m-b-100 width-400 w-75 m-auto"
          src={SpiritAnimalLogo}
          alt="spirit animal logo auburn road"
        />
        {documentToReactComponents(data[0].fields.mainContent, options)}
      </div>
    </>
  );
};

export default SpiritAnimal;
