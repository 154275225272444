import React, { useEffect } from "react";

export default function ReservationCTA(props) {
  useEffect(() => {
    let resScript;
    resScript = document.createElement("script");
    resScript.innerHTML = `!function(t,o,c,k){if(!t.tock){var e=t.tock=function(){e.callMethod?
    e.callMethod.apply(e,arguments):e.queue.push(arguments)};t._tock||(t._tock=e),
    e.push=e,e.loaded=!0,e.version='1.0',e.queue=[];var f=o.createElement(c);f.async=!0,
    f.src=k;var g=o.getElementsByTagName(c)[0];g.parentNode.insertBefore(f,g)}}(
    window,document,'script','https://www.exploretock.com/tock.js');

    tock('init', 'auburnroadvineyards');`;
    resScript.async = true;
    document.head.appendChild(resScript);
    return () => resScript.remove();
  }, []);

  const min = props.min ? "min" : null;
  if (!props.reverse) {
    return (
      <div
        className={`res-cta-wrap cta ${min} ${props.className} ${
          props.light && "light"
        }`}
        id="res-cta-wrap"
      >
        <div className="container d-flex flex-column flex-md-row align-items-center justify-content-around">
          <div className="flex-1 m-auto text-center text-md-start m-t-40">
            <h3 className="huge-text-no-quote m-b-30">Make a reservation</h3>
            <p>
              Come visit our European-style Vineyard Wine Bar, Patio and
              Courtyard surrounded by the vines – where we combine a cool vibe
              and down-to-earth attitude with excellent wine.
            </p>
          </div>
          <div
            className="d-flex flex-1 justify-content-center"
            id="reservation-widget"
          >
            <div
              id="Tock_widget_container"
              data-tock-display-mode="Button"
              data-tock-color-mode="Blue"
              data-tock-locale="en-us"
              data-tock-timezone="America/New_York"
              data-tock-offering="452840"
            ></div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div
        className={`res-cta-wrap cta ${min} ${props.className} ${
          props.light && "light"
        }`}
        id="res-cta-wrap"
      >
        <div className="container d-flex flex-column flex-md-row align-items-center justify-content-around">
          <div
            className="d-flex flex-1 justify-content-center"
            id="reservation-widget"
          >
            <div
              id="Tock_widget_container"
              data-tock-display-mode="Button"
              data-tock-color-mode="Blue"
              data-tock-locale="en-us"
              data-tock-timezone="America/New_York"
              data-tock-offering="452840"
            ></div>
          </div>
          <div className="flex-1 m-auto text-center text-md-start m-t-40">
            <h3 className="huge-text-no-quote m-b-30">Make a reservation</h3>
            <p>
              Come visit our European-style Vineyard Wine Bar, Patio and
              Courtyard surrounded by the vines – where we combine a cool vibe
              and down-to-earth attitude with excellent wine.
            </p>
          </div>
        </div>
      </div>
    );
  }
}
