import React from "react";
import { withRouter } from "react-router-dom";
import Logo from "../images/logo-dark.svg";

const SecondaryMenu = ({
  items,
  match: {
    params: { type },
  },
}) => {
  let wineTypeObj = {};

  items.forEach((item) => {
    const validMenuItem = item.fields.whichMenu.includes(type);
    const tag = item.metadata.tags[0] && item.metadata.tags[0].sys.id;
    if (!tag || !validMenuItem) return null;
    else if (!wineTypeObj[tag]) {
      wineTypeObj[tag] = [item];
    } else {
      wineTypeObj[tag].push(item);
    }
  });
  const renderWineItems = (wineType) => {
    return wineTypeObj[wineType].map((x) => {
      return (
        <div className="m-t-30 p-r-40 secondary-wine-item">
          <span className="m-b-0 menu-title font-size--large">
            {x.fields.item} &emsp;
            <span className="fw-bold">{x.fields.bottlePrice}</span>
          </span>
          {x.fields.smallDescription && (
            <p className="i font-size--small">{x.fields.smallDescription}</p>
          )}
          <p className="m-b-5">{x.fields.longDescription}</p>
        </div>
      );
    });
  };

  const renderTypes = Object.keys(wineTypeObj).map((x) => {
    if (wineTypeObj[x].length > 0) {
      return (
        <>
          <h2 className="mt-5">{x.charAt(0).toUpperCase() + x.slice(1)}</h2>
          {renderWineItems(x)}
        </>
      );
    } else return null;
  });

  if (items) {
    return (
      <div className="container p-t-100 p-b-100">
        <img className="width-300 m-auto" src={Logo} alt="auburn road logo" />
        <p className="text-center m-auto m-t-20">at</p>
        <h2 className="m-auto text-center">{type.split("-").join(" ")}</h2>
        <div className="p-t-100">{renderTypes}</div>
      </div>
    );
  } else {
    return (
      <>
        <div className="container text-center p-t-150 p-b-200">
          <img
            className="width-300 m-auto m-b-30"
            src={Logo}
            alt="auburn road logo"
          />
          <h2 className="huge-text-no-quote">Uh oh!</h2>
          <p>You've reached a page that doesnt exist.</p>
        </div>
      </>
    );
  }
};

export default withRouter(SecondaryMenu);
