import React, { useEffect } from "react";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { MARKS, BLOCKS } from "@contentful/rich-text-types";

const ToursTastings = ({ info }) => {
  useEffect(() => {
    document.title = "Auburn Road - Tours & Guided Tastings";
    return () => {
      document.title = "Auburn Road Vineyards";
    };
  }, []);
  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <span className="fw-bold">{text}</span>,
      [MARKS.ITALIC]: (text) => <span className="i">{text}</span>,
    },
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => (
        <img
          className="w-50 m-auto"
          src={node.data?.target?.fields?.file?.url}
          alt={node.data?.target?.fields?.title}
        />
      ),
    },
  };

  const pageTitle = info.fields.pageTitle;
  const pageContent = info.fields.bodyText;
  const mainImage = info.fields.mainImage.fields.file.url;
  const mainImageAlt = info.fields.mainImage.fields.title;
  return (
    <div className="container p-t-150 p-b-200">
      <h1 className="huge-text-no-quote mb-5">{pageTitle}</h1>
      <div className="row m-t-100">
        <div className="col-lg-4 col-md-12 mb-4  m-r-50">
          <img className="w-100" src={mainImage} alt={mainImageAlt} />
        </div>
        <div className="col-lg-7 col-md-12">
          {documentToReactComponents(pageContent, options)}
        </div>
      </div>
    </div>
  );
};

export default ToursTastings;
