import React, { useEffect } from "react";
import CornerLadybug from "../images/corner/corner-ladybug-art.webp";
import Flowers from "../images/flower-left.png";
import CornerEidolon from "../images/corner/corner-eidolon.webp";
import HandPouring from "../images/hand-pouring.png";
import Logo from "../images/logo-cropped.svg";
import CTA from "../components/CTA";
import GrapesHome from "../images/grapes-home.png";
import Button from "../components/Button";
import { withRouter } from "react-router-dom";

const Home = ({ history }) => {
  useEffect(() => {
    document.title = "Auburn Road Vineyards";
    return () => {
      document.title = "Auburn Road Vineyards";
    };
  }, []);
  return (
    <>
      <img className="corner-ladybug" src={CornerLadybug} alt="" />
      <div className="container">
        <div className="hero-flex">
          <div className="hero-CTA">
            <img className="hero-logo" src={Logo} alt="auburn road logo" />
            <h1 className="huge-text">
              Everything we do is <span className="accent-text">art</span>
            </h1>
            <h3 className="subheading">What makes us different?</h3>
            <p className="blurb">
              At Auburn Road the wine, the art, and the experience are truly
              like nowhere else. From grape to glass, and vine to table we
              ensure that "Everything Matters".
            </p>
            <div className="hero-buttons">
              <Button onClick={() => history.push("/our-wines")} type="brush">
                our wines
              </Button>
              <Button
                className="m-l-20"
                onClick={() =>
                  history.push({
                    pathname: "/whats-happening",
                    state: {
                      update: true,
                    },
                  })
                }
                type="plain"
              >
                what's happening
              </Button>
            </div>
          </div>
          <div className="hero-image">
            <img src={GrapesHome} alt="" />
          </div>
        </div>
      </div>
      <img className="flowers-home" src={Flowers} alt="" />
      <div className="inner-container m-auto m-t-100 p-b-100">
        <div className="art-cta">
          <div></div>
          <div className="EWDIS">
            <h1 className="huge-text m-t-100">
              You have <span className="accent-text">got</span> to see this
              place...
            </h1>
            <p className="blurb">
              ...our European-style Vineyard Wine Bar, Patio and Courtyard
              surrounded by the vines – where we combine a cool vibe and
              down-to-earth attitude with excellent wine. Let us seat you at
              your table and have our wonderful team of servers take good care
              of you.
            </p>
            <div className="d-flex enoteca-cta align-items-center">
              <Button
                className="m-r-20"
                onClick={() => history.push("/the-enoteca")}
                type="brush"
              >
                check it out
              </Button>
            </div>
          </div>
        </div>
      </div>

      <CTA
        light
        className="m-t-0 m-md-t-200 p-t-50 p-b-100 d-sm-flex flex-column flex-md-row align-items-center position-relative"
        flexLeft="3"
        flexRight="2"
        blurb={
          <div>
            <h4>Check out our Wine Stories</h4>
            <p>Read Auburn Road’s Wine Stories – like a blog, only cooler…</p>
            <Button
              onClick={() => history.push("/wine-stories")}
              type="secondary"
            >
              read them now
            </Button>
          </div>
        }
      >
        <h1 className="huge-text">
          You guys <br />
          should{" "}
          <span className="accent-text">
            write
            <br />
          </span>{" "}
          a book...
        </h1>
      </CTA>

      <div className="position-relative">
        <img className="corner-eidolon" src={CornerEidolon} alt="" />
        <img className="hand-pouring" src={HandPouring} alt="" />
        <div className="container d-flex flex-column-reverse flex-md-row p-t-200 align-items-center m-b-200">
          <div className="flex-1">
            <h1 className="huge-text">
              I want to be <span className="accent-text">part of this</span>{" "}
              somehow
            </h1>
            <p>
              Want to sign up for the Auburn Road Email Club? Or maybe
              participate in the quarterly Auburn Road Wine CSA? For more
              details, click below...
            </p>
            <div className="d-flex flex-column flex-md-row">
              <Button
                className="m-r-15"
                onClick={() => history.push("/auburn-road-wine-club")}
                type="brush"
              >
                Email Club
              </Button>
              <Button
                onClick={() => history.push("/auburn-road-csa")}
                type="brush"
              >
                Wine CSA
              </Button>
            </div>
          </div>
          <div className="flex-1"></div>
        </div>
      </div>
    </>
  );
};
export default withRouter(Home);
