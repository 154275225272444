import React, { useEffect } from "react";
import classnames from "classnames";
import Dessert from "../images/menu-dessert.png";
import CornerLadybug from "../images/corner/corner-ladybug-art.webp";
import Logo from "../images/logo-dark.svg";
import SheWolf from "../images/shewolf-art.png";
import Buddha from "../images/buddha-basket.png";
import Pizza from "../images/menu-pizza.png";
import Piadine from "../images/piadine.png";
import Affogato from "../images/menu-affogato.png";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { MARKS, BLOCKS } from "@contentful/rich-text-types";

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <span className="fw-bold">{text}</span>,
  },
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (node) => (
      <img
        className="w-50 m-auto"
        src={node.data?.target?.fields?.file?.url}
        alt={node.data?.target?.fields?.title}
      />
    ),
  },
};

const Menu = ({ items, cellarWines, hours, title }) => {
  useEffect(() => {
    document.title = `Auburn Road - ${title}`;
    return () => {
      document.title = "Auburn Road Vineyards";
    };
  }, [title]);
  let winesObj = {};

  items.forEach((item) => {
    const categories = item.metadata.tags;
    if (!categories.length > 0) {
      return null;
    }
    categories.forEach((category) => {
      const tag = category.sys.id;
      if (!winesObj[tag]) {
        winesObj[tag] = [item];
      } else {
        winesObj[tag].push(item);
      }
    });
  });

  const renderCellarWines = () => {
    if (cellarWines.length > 0) {
      return cellarWines.map((item) => {
        const { name, vintage, description, price } = item.fields;
        return (
          <div className="max-width-475 m-t-20 p-r-20 flex-grow-1">
            <div className="m-b-0 menu-title fw-bold font-size--large d-flex align-items-center">
              {name} &nbsp;
              <div className="background-color-wine color-white px-1 my-0 menu-title d-inline-block border-radius-5 font-size--small">
                {vintage}
              </div>
            </div>
            <span className="fw-bold">${price}</span>
            <br />
            <span className="m-b-0 menu-title"></span>
            <p className="m-b-5">{description}</p>
          </div>
        );
      });
    } else return null;
  };
  const renderWines = () => {
    const showWines = (type, column) => {
      const wines = type.map((x) => {
        const {
          glassPrice,
          soldout,
          bottlePrice,
          item,
          itemPrice,
          longDescription,
          smallDescription,
        } = x.fields;
        const renderItemPrice =
          glassPrice || bottlePrice
            ? `${bottlePrice} / ${glassPrice || "bottle only"}`
            : itemPrice;
        const renderSoldout = !soldout ? (
          <span className="fw-bold">{renderItemPrice}</span>
        ) : (
          <span className="text-danger">sold out</span>
        );

        return (
          <div
            className={classnames("max-width-475 m-t-20 p-r-20", {
              soldout: soldout,
            })}
          >
            <span className="m-b-0 menu-title fw-bold font-size--large">
              {item} &emsp;
              {renderSoldout}
            </span>
            {smallDescription && (
              <p className="i font-size--small">{smallDescription}</p>
            )}
            <p className="m-b-5">{longDescription}</p>
          </div>
        );
      });
      return (
        <div
          className={classnames("m-auto d-flex flex-wrap", {
            "flex-column": column,
          })}
        >
          {wines}
        </div>
      );
    };

    return (
      <div>
        {showWines(winesObj["flights"], true)}
        <div className="d-flex flex-md-row m-t-100 flex-column flex-md-column">
          <div className="flex-1">
            <h2 className="text-center">DRY WHITE AND BLUSH WINES</h2>
            {showWines(winesObj["white"])}
          </div>
          <div className="flex-1 m-t-75 m-md-t-100">
            <h2 className="text-center">SWEET AND FRUIT WINES</h2>
            {showWines(winesObj["sweetFruit"])}
          </div>
          <div className="flex-1 m-t-75 m-md-t-100">
            <h2 className="text-center">SPARKLING</h2>
            {showWines(winesObj["sparkling"])}
          </div>
        </div>
        <div className="d-flex flex-md-row m-t-100 flex-column flex-md-column">
          <div className="flex-1">
            <h2 className="text-center">DRY RED WINES </h2>
            {showWines(winesObj["red"])}
          </div>
          <div className="flex-1 m-t-75 m-md-t-50">
            <h2 className="text-center">FORTIFIED WINES</h2>
            {showWines(winesObj["fortified"])}
          </div>
        </div>
        {cellarWines && (
          <div className="d-flex flex-column flex-md-column m-t-100">
            <div className="flex-1 m-t-75 m-md-t-0">
              <div className="background-color-wine p-3 max-width-500 m-auto color-white">
                <h2 className="text-center">Cellar Wines</h2>
                <p className="text-center my-0">
                  Available to Wine CSA Members only and to participants in
                  Guided Premium Tastings on days when offered.
                </p>
                <p className="text-center">**Bottle sales only</p>
              </div>
              <div className="m-auto d-flex flex-wrap my-2">
                {renderCellarWines()}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderItems = (category, lastItem = () => null) => {
    const itemList = items.filter((x) => {
      if (x.fields.category.includes(category)) {
        return true;
      } else return false;
    });
    return (
      <div className="d-flex flex-column-reverse justify-content-end flex-wrap flex-2 max-width-600">
        {lastItem()}
        {itemList.map((x) => {
          if (x && x.fields.item) {
            const menuItem = x.fields;
            const soldout = menuItem.soldout;
            let item;
            if (category === "Pizza") {
              const splitItem = menuItem.item.split("/");
              item = (
                <p>
                  <span className="fw-bold">{splitItem[0]}</span>
                  <span>/{splitItem[1]}</span>
                </p>
              );
            } else {
              item = menuItem.item;
            }

            return (
              <div className="p-r-20">
                <span className="m-b-0 fw-bold menu-title font-size--large">
                  {item} &emsp;
                  {!soldout ? (
                    <span className="fw-bold">
                      {menuItem.itemPrice
                        ? menuItem.itemPrice
                        : `${
                            menuItem.bottlePrice + " / " + menuItem.glassPrice
                          }`}
                    </span>
                  ) : (
                    <span className="text-danger">sold out</span>
                  )}
                </span>
                {menuItem.smallDescription && (
                  <p className="i font-size--small">
                    {menuItem.smallDescription}
                  </p>
                )}
                <p className="">
                  {menuItem.richTextDesc
                    ? documentToReactComponents(menuItem.richTextDesc, options)
                    : menuItem.longDescription}
                </p>
              </div>
            );
          }
          return null;
        })}
      </div>
    );
  };
  return (
    <>
      <img className="corner-ladybug" src={CornerLadybug} alt="" />
      <div className="container p-t-150 menu-wrap">
        <img src={Logo} className="width-250 m-auto" alt="" />
        <h1 className="m-b-100 m-t-15 text-center">Menu</h1>
        <p>
          <i>**There is an automatic 20% gratuity on parties of 5 or more.</i>
        </p>
        <div className=" m-auto">
          <h2 className="huge-text-no-quote m-b-40" name="wine">
            Wine
          </h2>
          {renderWines()}
          <div className=" m-auto">
            <h2
              className="m-b-30 m-t-150 huge-text-no-quote"
              name="buddha baskets"
            >
              Buddha Baskets
            </h2>
            <p className="w-75 m-b-100">
              Our Buddha Baskets are a gourmet accompaniment to your wine
              experience - curated daily. Artfully arranged little bites of
              everything you need to enjoy wine. Think “buddha bowl” for wine
              lovers.
              <br />
              Includes crackers, jams and more!
              <br />
              *Gluten Free Crackers available upon request; no additional
              substitutions*
            </p>
            <div className="m-auto d-flex flex-sm-row">
              {renderItems("Buddha Basket", () => {
                return (
                  <div>
                    <div className="m-b-0 menu-title font-size--large">
                      A La Carte:
                    </div>
                    <span>
                      Little Bowl of Spanish Peanuts - &emsp; 4 <br />
                    </span>
                    <span>
                      Olives &emsp; 4 <br />
                    </span>
                    <span>
                      Hummus &emsp; 4<br />
                    </span>
                    <span>
                      Featured Sliced Deli Meat &emsp; 6<br />
                    </span>
                    <span>Crostini Crackers &emsp; 4</span>
                  </div>
                );
              })}
              <div className="flex-2 menu-pizza">
                <img className="w-100 " src={Buddha} alt="buddha basket" />
              </div>
            </div>
          </div>
          <div className=" mt-5 pt-5">
            <h2
              className="m-t-100 m-b-30 huge-text-no-quote"
              name="soup & salad"
            >
              Soup & Salad
            </h2>
            <div className="d-flex">{renderItems("Soup Salad")}</div>
          </div>
          <div className=" mt-5 pt-5">
            <h2 className="m-t-100 huge-text-no-quote" name="piadine">
              Piadine
            </h2>
            <p className="m-b-40 max-width-400">
              Delicious Italian street sandwiches made with house-baked bread
              from our wood -fired oven.
            </p>
            <div className="d-flex">
              {renderItems("Piadine")}
              <div className="flex-1  menu-piadine">
                <img className="w-75" src={Piadine} alt="piadine" />
              </div>
            </div>
          </div>
          <h2 className="m-b-30 m-t-100 huge-text-no-quote" name="pizza">
            Wood-fired Pizza
          </h2>
          <p className="fw-bold bg-dark text-white width-300 py-2 p-l-10 m-b-75">
            Available ONLY on <br />
            {hours[0].fields.pizzaHours.map((x) => {
              return (
                <span className="hours-item">
                  <span className="b">{x.key}: </span>
                  {x.value}
                  <br />
                </span>
              );
            })}
          </p>
          <div className="m-auto d-flex flex-column flex-sm-row justify-content-between">
            {renderItems("Pizza", () => (
              <div>**Add prosciutto, pepperoni or sausage - 2</div>
            ))}
            <div className="flex-1">
              <img className="w-75 menu-pizza" src={Pizza} alt="pizza" />
            </div>
          </div>
        </div>
      </div>

      <img src={SheWolf} className="menu-shewolf" alt="" />
      <div className="container p-t-50">
        <div className="inner-container m-auto">
          <h2
            className="text-center m-b-75 m-t-150 huge-text-no-quote"
            name="dessert"
          >
            Desserts
          </h2>
          <div className="m-auto d-flex flex-column flex-sm-row justify-content-between">
            {renderItems("Dessert")}
            <div className="flex-2">
              <img
                className="w-100 menu-dessert"
                src={Dessert}
                alt="auburn road dessert"
              />
            </div>
          </div>
        </div>
        <div className="inner-container m-auto m-t-75 m-b-150">
          <h2
            className="text-center m-b-75 m-t-200 huge-text-no-quote"
            name="drinks"
          >
            Coffee and other beverages
          </h2>
          <div className="m-auto d-flex flex-column flex-sm-row justify-content-between">
            {renderItems("Coffee")}
            <div className="flex-2">
              <img
                className="w-100 menu-coffee"
                src={Affogato}
                alt="affogato"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Menu;
