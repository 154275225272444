import React from "react";
import { withRouter } from "react-router-dom";
import classnames from "classnames";
import Logo from "../images/logo-dark.svg";
import GoToTop from "../GoToTop";
import Button from "../components/Button";
import { MenuLinks } from "./utils";
import { Link } from "react-scroll";

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      scrollLock: false,
    };
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  closeMenu = () => {
    this.setState({
      showMenu: false,
    });
  };

  componentWillUnmount() {
    document.removeEventListener("mouseup", this.handleClickOutside);
  }
  handleClickOutside(event) {
    if (this.state.showMenu) {
      if (
        this.wrapperRef.current &&
        !this.wrapperRef.current.contains(event.target)
      ) {
        this.setState({ showMenu: false });
      }
    }
  }

  renderMainNav = (currentPage) => {
    return (
      <nav
        ref={this.wrapperRef}
        className={classnames("menu", {
          "show-menu": this.state.showMenu,
        })}
      >
        <div className="menu-content">
          <img
            src={Logo}
            onClick={() => this.props.history.push("/")}
            className="logo m-t-50"
            alt="logo"
          ></img>
          <Button
            className="width-150"
            onClick={() =>
              window.open(
                "https://auburn-road-vineyard-winery-enoteca.square.site/",
                "_blank"
              )
            }
            type="secondary"
          >
            Buy Online
          </Button>
          <div id="reservation-widget"></div>
          <Button
            className="link main-menu-item justify-content-start outgoing-link p-0 m-t-40"
            href="https://www.exploretock.com/auburnroadvineyards/search?date=2024-01-10&size=2&time=12%3A45"
          >
            Reservations
          </Button>
          <MenuLinks
            currentPage={currentPage}
            toggleContactOverlayFn={this.props.toggleContactOverlay}
            closeMenu={this.closeMenu}
          />
        </div>
      </nav>
    );
  };

  renderLocalNav = () => {
    const links = [
      "wine",
      "buddha baskets",
      "soup & salad",
      "piadine",
      "pizza",
      "dessert",
      "drinks",
    ];
    return (
      <nav
        ref={this.wrapperRef}
        className={classnames("menu", {
          "show-menu": this.state.showMenu,
        })}
      >
        <div className="menu-content">
          <img
            src={Logo}
            onClick={() => this.props.history.push("/")}
            className="logo m-t-50"
            alt="logo"
          ></img>
          {links.map((link) => {
            return (
              <Link
                onClick={this.closeMenu}
                className="local-menu-item pointer"
                activeClass="active"
                to={link}
                spy={true}
                smooth={true}
                offset={-50}
                duration={500}
              >
                {link.charAt(0).toUpperCase() + link.slice(1)}
              </Link>
            );
          })}
        </div>
      </nav>
    );
  };

  render() {
    const currentPage = this.props.history.location.pathname;

    return (
      <>
        <GoToTop />
        <div ref={this.wrapperRef}>
          {!this.state.showMenu ? (
            <div
              onClick={() => {
                this.setState({ showMenu: true });
              }}
              className="nav-icon nav-open-icon"
            ></div>
          ) : (
            <div
              onClick={() => {
                this.setState({ showMenu: false });
              }}
              className="nav-icon nav-close-icon"
            ></div>
          )}
          {currentPage === "/menu-local"
            ? this.renderLocalNav(currentPage)
            : this.renderMainNav()}
        </div>
      </>
    );
  }
}

export default withRouter(Navigation);
